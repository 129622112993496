import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
//import VueCreditCardValidation from 'vue-credit-card-validation'

//axios.defaults.baseURL = 'http://127.0.0.1:8000'
//axios.defaults.baseURL = 'https://b2bsearch.co'
axios.defaults.baseURL = 'https://dev.b2bsearch.co'

//axios.defaults.baseURL = process.env.VUE_APP_LMS_URL

//createApp(App).use(store).use(router, axios).use(VueCreditCardValidation).mount('#app')
createApp(App).use(store).use(router, axios).mount('#app')

