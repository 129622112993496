<template>
	<!--pre>{{project_id}} {{this.$store.state.allEmlInProgr}} {{this.$store.state.allEmlInProgr.indexOf(project_id)}} {{this.$store.state.allEmlInProgr.indexOf(project_id) > -1}}</pre-->
	<button 
		v-if="item.email_list.length == 0"  
		:class="{'button':true, 'is-loading':emlClicked || this.$store.state.allEmlInProgr.indexOf(project_id) > -1}" 
		@click="emailSaas"
	>
			&#9993; Get Emails 
			<span class="credits1">4</span>
	</button>
	<div v-else>
		<strong>E-mails:</strong><br>
		<!-- <div v-html="html1"></div> -->
		<div v-for="eml in item.email_list">
			<!-- <span @click="emailClick(eml.addr)" :class="{is_addr: is_addr}">{{eml.addr}}</span> -->

			<span v-if="eml.addr === 'Not found'">Not found</span>
			<span v-else class="is_addr" @click="$emit('email-click', eml.addr)">{{eml.addr}}</span> 
			
			<span class="eml2"> (as of {{eml.upd_date.toString().split("T")[0]}})</span><br>
			
		</div>
	</div>

	
</template>

<script>
    import axios from 'axios'

    export default {
    	name: 'EmailSaas',

    	props: {
    		item: {},
    		project_id: 0
    		//mail_list_status: 0,
    	},
    	emits: ['email-click'],
    	data() {
    		return {
    			html1: '',
    			email_list: [
    				{
    					addr: '',
    					upd_date: ''
    				}
    			],
    			is_addr: false,
    			emlClicked: false,
    			credits_out: false,

    		}
    	},

    	methods:{
		    async emailSaas() {
		        //this.$store.commit('setIsLoading', true)
		        //console.log("EmailSaas item:", this.item)
		        this.emlClicked = true

		        if (!this.item.name) {
		        	this.item.name = this.item.contact_person
		        }
		        //console.log("EmailSaas item:", this.item)
		        
		        await axios
		            .get(`/lms/email/find/${this.item.id}`)
		            .then(response => {
		            	this.$store.state.teamCredits = response.data.team_credits
		            	if (response.data.email_list != 'Not enough credits') 
		            	{
		                	this.item.email_list = response.data.email_list
		                	//console.log("this.item.email from saas", this.item.email_list)
			                if (this.item.email_list[0].addr != 'Not found') {
								this.$store.state.isProjLeadsEmail = true
			                }
			            } else {
			            	this.$store.state.creditsOut = true
			            }
		            })
		         	.catch(error => {
                        console.log(error)
                    })
		        this.emlClicked = false
		        //this.$store.commit('setIsLoading', false)
		    },
		}
	}
</script>

<style lang="scss">
	.eml2 {
		font-size: smaller;
		color: #999;
	}
	.is_addr {
		cursor: pointer;
		color: #3273dc;
		&:hover {
			color: #006;
		}
	}
</style>