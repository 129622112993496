import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

import Test from '../views/Test.vue'
import Home from '../views/Home.vue'
// import SaLinkedin from '../views/SaLinkedin.vue'
// import Search from '../components/Search.vue'
// import AdvSearch from '../components/AdvSearch.vue'
import Projects from '../views/Projects.vue'
import Project from '../views/Project.vue'

import ProjectFall from '../views/ProjectFall.vue'

import PSearches from '../views/PrevSearches.vue'
import SearchResult from '../views/SearchResult.vue'

import PubArticle from '../views/PubArticle.vue'
import ResetEmail from '../views/ResetEmail.vue'
import ResetInvite from '../views/ResetInvite.vue'
import NewPassword from '../views/NewPassword.vue'

import SignUp from '../views/SignUp.vue'
import LogIn from '../views/LogIn.vue'
import ConfirmEmail from '../views/ConfirmEmail.vue'
import ConfirmEmailWarn from '../views/ConfirmEmailWarn.vue'
import Dashboard from '../views/dashboard/Dashboard.vue'
import MyAccount from '../views/dashboard/MyAccount.vue'
import Leads from '../views/dashboard/Leads.vue'
import Lead from '../views/dashboard/Lead.vue'
import AddLead from '../views/dashboard/AddLead.vue'
import EditLead from '../views/dashboard/EditLead.vue'
// import AddComment from '../views/dashboard/AddComment.vue'
// import EditComment from '../views/dashboard/EditComment.vue'
import AddTeam from '../views/dashboard/AddTeam.vue'
import Team from '../views/dashboard/Team.vue'
import AddMember from '../views/dashboard/AddMember.vue'
// import Clients from '../views/dashboard/Clients.vue'
// import AddClient from '../views/dashboard/AddClient.vue'
// import Client from '../views/dashboard/Client.vue'
// import EditClient from '../views/dashboard/EditClient.vue'
// import AddNote from '../views/dashboard/AddNote.vue'
// import EditNote from '../views/dashboard/EditNote.vue'
import EditMember from '../views/dashboard/EditMember.vue'
import Plans from '../views/dashboard/Plans.vue'
import PlansThankyou from '../views/dashboard/PlansThankyou.vue'
import Actions from '../views/Actions.vue'
// import Payment from '../views/Payment.vue'
import LandingMR from '../views/LandingMR.vue'
import LandingHR from '../views/LandingHR.vue'

const routes = [
  {
    path: '/test',
    name: 'Test',
    component: Test
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
    {
    path: '/welcome-mr',
    name: 'LandingMR',
    component: LandingMR
  },
  {
    path: '/welcome-hr',
    name: 'LandingHR',
    component: LandingHR
  },
  {
    path: '/line-search',
    name: 'Search',
    // component: Search,

    // route level code-splitting
    // this generates a separate chunk (search.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "search" */ '../components/Search.vue'),
    meta: {
      requireLogin: true
    },
  },
  {
    path: '/search/adv',
    name: 'AdvSearch',
    // component: AdvSearch,
    meta: {
      requireLogin: true
    },
    component: () => import(/* webpackChunkName: "advsearch" */ '../components/AdvSearch.vue')
  },
  {
    path: '/pub/:article_url',
    name: 'PubArticle',
    component: PubArticle,
  },
  {
    path: '/team-searches',
    name: 'PSearches',
    component: PSearches,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/search/:id',
    name: 'SearchResult',
    component: SearchResult,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/project',
    name: 'Projects',
    component: Projects,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/project/:id',
    name: 'Project',
    component: Project,
    meta: {
      requireLogin: true
    }
  },
    {
    path: '/project/:id/fall',
    name: 'ProjectFall',
    component: ProjectFall,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUp
  },
  {
    path: '/log-in',
    name: 'LogIn',
    component: LogIn
  },
  {
    path: '/confirmed/:uid/:token',
    name: 'ConfirmEmail',
    component: ConfirmEmail
  },
    {
    path: '/confirm-email',
    name: 'ConfirmEmailWarn',
    component: ConfirmEmailWarn
  },
  {
    path: '/reset-pwd',
    name: 'ResetEmail',
    component: ResetEmail,
  },
  {
    path: '/reset-invite',
    name: 'ResetInvite',
    component: ResetInvite,
  },
  {
    path: '/password/reset/confirm/:uid/:token',
    name: 'NewPassword',
    component: NewPassword
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/my-account',
    name: 'MyAccount',
    component: MyAccount,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/team',
    name: 'Team',
    component: Team,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/team/plans',
    name: 'Plans',
    component: Plans,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/team/plans/thankyou',
    name: 'PlansThankyou',
    component: PlansThankyou,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/edit-member/:id',
    name: 'EditMember',
    component: EditMember,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/team/add-member',
    name: 'AddMember',
    component: AddMember,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/add-team',
    name: 'AddTeam',
    component: AddTeam,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/leads',
    name: 'Leads',
    component: Leads,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/leads/add',
    name: 'AddLead',
    component: AddLead,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/leads/:id',
    name: 'Lead',
    component: Lead,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/leads/:id/edit',
    name: 'EditLead',
    component: EditLead,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/team/actions',
    name: 'Actions',
    component: Actions,
    meta: {
      requireLogin: true
    }
  },
    {
    path: '/team/payment',
    name: 'Payment',
    // component: Payment,
    component: () => import('../views/Payment.vue'),
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/team/payments',
    name: 'Payments',
    meta: {
      requireLogin: true
    },
    component: () => import(/* webpackChunkName: "advsearch" */ '../views/Payments.vue')
  },
  {
    path: '/team/payment/thankyou',
    name: 'PayThankYou',
    component: () => import('../views/PayThankYou.vue'),
    meta: {
      requireLogin: true
    }
  },
  /*{
    path: '/dashboard/leads/:id/add-comment',
    name: 'AddComment',
    component: AddComment,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/leads/:id/edit-comment/:comment_id',
    name: 'EditComment',
    component: EditComment,
    meta: {
      requireLogin: true
    }
  },
  
  {
    path: '/dashboard/clients',
    name: 'Clients',
    component: Clients,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/clients/add',
    name: 'AddClient',
    component: AddClient,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/clients/:id',
    name: 'Client',
    component: Client,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/clients/:id/edit',
    name: 'EditClient',
    component: EditClient,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/clients/:id/add-note',
    name: 'AddNote',
    component: AddNote,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/clients/:id/edit-note/:note_id',
    name: 'EditNote',
    component: EditNote,
    meta: {
      requireLogin: true
    }
  },
  */
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
    next('/log-in')
  } else {
    next()
  }
})

export default router
