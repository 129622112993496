<template>
    <div class="container">
        <div class="columns">
            <div class="column is-8 is-offset-2">
                <div class="myacc">
                    My account: <span class="myshow">{{$store.state.user.username}}</span>
                </div>

                <div class="buttons">
                    <router-link :to="{ name: 'EditMember', params: { id: $store.state.user.id }}" class="button is-light">Edit</router-link>

                    <button @click="logout()" class="button is-warning">Log out</button>
                </div>


                <!--
                <div v-if="pwd_reset">
                    Password reset requested. Please, check youe mail box for further instructions.
                </div>
                -->
                <hr />
                <div class="myacc">
                    My team: <span class="myshow">{{team.name}}</span>
                </div>
                

                <!--
                <p><strong>Plan: </strong>{{ $store.state.team.plan }}</p>
                <p><strong>Max clients: </strong>{{ $store.state.team.max_clients }}</p>
                <p><strong>Max leads: </strong>{{ $store.state.team.max_leads }}</p>
                <p v-if="$store.state.team.plan !== 'Free'"><strong>Plan end date: </strong>{{ team.plan_end_date }}</p>
                
                <p>
                    <router-link :to="{'name': 'Plans'}">Change plan</router-link>
                </p>
                -->


                <div class="myshow">Members:</div>


                <div
                    v-for="(member, memidx) in team.members"
                    v-bind:key="member.id"
                >
                    <span class="team_uname">[ {{ member.username }}] </span>
                    <span class="team_fn"> {{ member.first_name }} {{ member.last_name }}</span>
                    <span v-if="team.name === $store.state.user.username && member.username != $store.state.user.username" 
                        @click="removeMemberWarn(member.username, memidx)"
                        class="dtch"
                        >✀&nbsp;Remove from team</span>
                </div>

                <br/>
                <template v-if="team.created_by.id === parseInt($store.state.user.id)">
                    <router-link :to="{'name': 'AddMember'}" class="button is-primary">Invite new member</router-link>
                </template>
                <hr />
                <div class="myacc">
                    Team balance: <span class="myshow"><b>{{$store.state.teamCredits}}</b> credits</span>
                </div>
                <router-link class="button is-primary" to="/team/payment">Buy credits</router-link>
                <br /><br />
                <router-link to="/team/payments">Payment History</router-link>
                <hr />
                <div>
                    <div class="myacc">Usage stats</div>
                    <router-link to="/team-searches">My Team Searches</router-link>
                    <br /><br />
                    <router-link to="/team/actions">My Team Actions</router-link>
                
                </div>
            </div>
        </div>

        <div class="modal" :class="{'is-active': userRemoveWarn}">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                  <p class="modal-card-title">Removing User: {{removeMemberName}}</p>
                  <!-- <button class="delete" aria-label="close" @click="cancelModal"></button> -->
                </header>
                <section class="modal-card-body"> 
                    
                    <div>
                        The user will be disconnected from the team. All projects and leads created and enriched by the user will remain associated with this team. The user will not have access to these projects and leads.
                    </div>
                    
                    
                </section>
                <footer class="modal-card-foot">
                    <button  :class="{'button':true, 'is-primary':true}" @click="removeMember(removeMemberName, removeMemberIdx)">Remove User</button>
                    <button class="button" @click="cancelRemoveMember">Cancel</button>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    import { toast } from 'bulma-toast'

    export default {
        name: 'MyAccount',
        data() {
            return {
                team: {
                    members: [],
                    created_by: {}
                },
                me: {
                    username: ''
                },
                removeMemberName: '',
                removeMemberIdx: '',
                userRemoveWarn: false,
            }
        },
        mounted() {
            this.getMe()
            this.getTeam()
        },
        methods: {
            async getMe() {
            this.$store.commit('setIsLoading', true)

            await axios
                .get('/lms/users/me/')
                .then(response => {
                    this.me = response.data
                    console.log("ME", this.me)
                })
                .catch(error => {
                    console.log(error)
                })
            
            this.$store.commit('setIsLoading', false)
            },
            async getTeam() {
                this.$store.commit('setIsLoading', true)

                await axios
                    .get('/lms/teams/get_my_team/')
                    .then(response => {
                        this.team = response.data
                        this.$store.state.teamCredits = this.team.credits
                    })
                    .catch(error => {
                        console.log(error)
                    })
                
                this.$store.commit('setIsLoading', false)
            },
            async removeMemberWarn(member_username, member_idx) {
                this.removeMemberName = member_username
                this.removeMemberIdx = member_idx
                this.userRemoveWarn = true
            },
            async cancelRemoveMember() {
                this.userRemoveWarn = false
            },
            async removeMember(member_username, member_idx) {
                this.$store.commit('setIsLoading', true)
                console.log('remove member_username', member_username)
                await axios
                    .post('/lms/teams/remove_member/', {"email":member_username})
                    .then(response => {
                        this.resp = response.data
                        this.userRemoveWarn = false
                        this.team.members.splice(member_idx, 1);
                    })
                    .catch(error => {
                        console.log(error)
                    })
                    console.log("this.resp", this.resp)
                this.$store.commit('setIsLoading', false)
            },
            async logout() {
                await axios
                    .post('/lms/token/logout/')
                    .then(response => {
                        console.log('Logged out')
                    })
                    .catch(error => {
                        console.log(JSON.stringify(error))
                    })
                
                axios.defaults.headers.common['Authorization'] = ''
                localStorage.removeItem('token')
                localStorage.removeItem('username')
                localStorage.removeItem('userid')
                localStorage.removeItem('team_name')
                localStorage.removeItem('team_id')
                this.$store.commit('removeToken')

                this.$router.push('/')
            },

        }
    }
</script>
<style>
    .myacc {
        font-weight: 400;
        font-size: larger;
        color: #630;
        margin-bottom: 18px;
    }
    .myshow {
        color: #444;
        font-weight: 600;

    }
    .dtch {
        color:#a30;
        cursor: pointer;
        text-decoration: underline;
        background-color: #fee;
    }
    .likebutton {
        display: inline-block;

    justify-content: center;
    padding-bottom: calc(0.5em - 1px);
    padding-right: 1em;
    padding-top: calc(0.5em - 1px);
    white-space: nowrap;

    }
</style>