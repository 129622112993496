<template>
    <div class="container">
        <div class="columns is-multiline">
	        <div v-if="project === 'rejected'">
	            <h1 class="title">Not found</h1>
	        </div>
			<div  v-else class="column is-12">

				<p><strong>Project</strong> created by: {{project.created_by}}; 
			    	{{project.created_at.replace("T"," ").split(".")[0]}}
			    </p>
			    <h1 @click="editProject" class="title">{{project.title}}&nbsp;<span class="smic">&#x1F4DD;</span> </h1>
			    <div @click="editProject">Project status: <strong>{{project.status_comment}}</strong> <span class="smic">&#x1F4DD;</span> </div>

		    	<div>Candidates in Project: {{leads.length}}
		    		<br>Emails in Project:
			    	<input type="checkbox" id="emlFnd" @click="ldDisplayFnd = !ldDisplayFnd" checked>
			    	<label for="emlFnd"> found: {{emailCount.found}}</label>
					<!--small>[{{Math.round(emailCount.found / leads.length * 100)}}%]</small-->
		    		 | 
		    		<input type="checkbox" id="emlNF" @click="ldDisplayNF = !ldDisplayNF" checked> 
			    	<label for="emlNF"> not found: {{emailCount.notfound}} </label>
		    		<!--small>[{{Math.round(emailCount.notfound / leads.length * 100)}}%]</small-->
		    		 |  
		    		<input type="checkbox" id="emlNE" @click="ldDisplayNE = !ldDisplayNE" checked> 
			    	<label for="emlNE"> not enriched: {{emailCount.notenriched}} </label>
			    	<!--small>[{{Math.round(emailCount.notenriched / leads.length * 100 )}}%]</small-->
		    	</div>
		    	
			    
			    <br /><br />
			    <ContentButton :cbtn="{title: 'Working with Project', url: 'help-project', padding: '15px'}" />
			    <br /><br />

			    <div class="buttons">	
			    	<EmailAll :project="project" :emailCount="emailCount" :leads="leads" @mlist-finish="initProject"/>
			    	<button class="button" @click="msgHidden = !msgHidden">
						&#128233; Email template {{msgHidden ? '&#9660;' : '&#9650;'}}
					</button>

					<SaveXls :project="project" />
					<SaveCsv :results="leads" :project="project" />
					
				</div>

			    <div v-if="!msgHidden" class="prefl">

			    	<p>
			    	Here you can create an email template to send to candidates from this project below. The template text and subject will be copied to every email you will open in your default email program (such as MS Outlook or Gmail) by clicking on the candidate's email address in his profile.
			    	</p>
			    	<br/>
			        <form @change="saveProject">
			            <div class="field">
			                <!-- label class="sm">Message Subject</label -->
			                <div class="control">
			                    <input type="text" class="input" 
			                        placeholder="Subject for messages created"
			                        v-model="project.msg_subject"
			                    >
			                </div>
			            </div>
			            <div class="field">
			                <!-- label class="sm">Message Body</label -->
			                <div class="control">
			                    <textarea rows="4" class="textarea" 
			                        placeholder="Body for messages created"
			                        v-model="project.msg_body" 
			                    >{{project.msg_body}}</textarea>
			                </div>
			            </div>
			        </form>
			    </div>

			    <!--div v-if="emailCount.found !== 0">
			    	<input type="checkbox" id="showEmLeads" @click="ldDisplay = !ldDisplay"><label for="showEmLeads"> w/email only</label>
				</div-->
			    
			    <!-- Leads -->
			    <div v-for="(lead, idx) in leads">
			    	<!--div v-if="isLeadEmail(lead)" class="ld_itm"-->
			    	<div v-if="isLeadEmail1(lead)" class="ld_itm">
			    		<button class="cutp" @click="removeFromProject(idx)">&#9988;&nbsp;Cut from project</button>
				        <LeadInList :lead="lead" :project="project"/>
				        
			    	</div>
			    </div>
			</div>
        </div>
    </div>

    <!-- Project title edit in modal-->
	<div class="modal" :class="{'is-active': showModalEditTitle}">
	    <div class="modal-background"></div>
	    <div class="modal-card">
	        <section class="modal-card-body">            

                Edit Project Title:
                <div class="control">
                    <input type="text" class="input" v-model="project.title">
                </div>
                Edit Project Status Comment:
                <div class="control">
                    <input type="text" class="input" v-model="project.status_comment">
                </div>

	        </section>
	        <footer class="modal-card-foot">
	        	<button class="button is-success" @click="saveProject">Save</button>
	            <button class="button" @click="cancelModal">Cancel</button>
	        </footer>
	    </div>
	</div>
</template>

<script>
	import axios from 'axios'
	import ContentButton from '@/components/services/ContentButton'
	import LeadInList from '@/components/services/LeadInList'
	import ProjectMessage from '@/components/services/ProjectMessage'
	import SaveCsv from '@/components/services/SaveCsv.vue'
	import SaveXls from '@/components/services/SaveXls.vue'
	import EmailAll from '@/components/EmailAll.vue'
	import { toast } from 'bulma-toast'

    export default {
    	name: 'Project',
    	components: {LeadInList, ProjectMessage, SaveCsv, SaveXls, ContentButton, EmailAll},
    	data(){return{
        	project: {
        		title: '',
        		created_at: '',
        		created_by: '',
        		msg_subject: '',
        		msg_body: '',
        		mail_list_status: 0
        	},
            leads: [],
            showModalEditTitle: false,
            msgHidden: true,
            ldDisplay: true,
            ldDisplayFnd: true,
            ldDisplayNF: true,
            ldDisplayNE: true,
            projEmails: false,
            PRICE_XLS: 20,
            emailCount: {
            	found: 0,
            	notfound: 0,
            	notenriched: 0
            },
            //emailFound: 'nf',
            timerId: 0
        }},
        mounted() {
            this.initProject()
        },
    	methods:{
    		
		    async initProject() {
		        this.$store.commit('setIsLoading', true)

		        const projectID = this.$route.params.id

		        await axios
		            .get(`/lms/project/${projectID}`)
		            .then(response => {
		            	console.log(response.data)
		            	if (response.data.rejected) {
		            		this.project = 'rejected'
		            	} else {
			                this.project = response.data.project
			                this.leads = response.data.leads
			                this.countEmails(this.leads)
		            	}
		            	this.$store.state.teamCredits = response.data.team_credits
		            })
		            .catch(error => {
                        console.log(error)
                    })
		        
		        this.$store.commit('setIsLoading', false)
		    },
		    countEmails(leads) {
		    	//console.log('countEmails', leads)
		    	this.emailCount = {
            		found: 0,
            		notfound: 0,
            		notenriched: 0
            	}
		    	for (const lead of leads) {
		    		if (lead.email_list.length > 0) { 
		    			if (lead.email_list[0].addr === 'Not found') {
		    				this.emailCount.notfound += 1
						} else {
							this.emailCount.found += 1
						}
		    		} else {
		    			this.emailCount.notenriched += 1
		    		}
		    	}		    	
		    },
		    /*
		    isLeadEmail(lead) {
		    	if (lead.email_list.length > 0 ) {
		    		if (lead.email_list[0].addr != 'Not found') {
		    			return true
		    		}
		    	}
		    	return this.ldDisplay
		    },
		    */
		    isLeadEmail1(lead) {
		    	// one lead: detect type of email enrichment result
		    	// against checked status for this type of email enrichment
		    	// console.log("NE", this.ldDisplayNE, "NF", this.ldDisplayNF, "FO", this.ldDisplayFnd)
		    	if (lead.email_list.length === 0) { // not enriched
		    		if(this.ldDisplayNE) {
		    			return true //show
		    		}
		    	} else { // enriched
		    		if (lead.email_list[0].addr === 'Not found') {
			    		if(this.ldDisplayNF) {
			    			return true
			    		}
		    		} else { 
		    			if (this.ldDisplayFnd) {
		    				return  true
		    			}
		    		}
	    		}

	    		return false // dont show

		    },
		    editProject() {
                this.showModalEditTitle = true;
                this.backup = JSON.parse(JSON.stringify(this.project))
            },
            cancelModal() {
                for(var k in this.project) this.project[k]=this.backup[k]
                this.showModalEditTitle = false
            },
            async saveProject() {
                this.$store.commit('setIsLoading', true)
                //console.log('saveProject', this.project)
        
                await axios
                    .post(`/lms/project/${this.project.id}/update`, this.project)
                    .then(response => {
                        toast({
                            message: 'Saved',
                            type: 'is-success',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 1200,
                            position: 'center',
                        })
                        this.showModalEditTitle = false;                       
                    })
                    .catch(error => {
                        console.log(error)
                    })

                this.$store.commit('setIsLoading', false)
            },
            async removeFromProject(idx) {
                this.$store.commit('setIsLoading', true)
                console.log('pj, ld:', this.project.id, this.leads[idx].id)
                await axios
                	.post(`/lms/project/${this.project.id}/lead-remove/${this.leads[idx].id}`)
                	.then(response => {
                        toast({
                            message: 'Removed',
                            type: 'is-success',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 1200,
                            position: 'center',
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })
				this.leads.splice(idx, 1)
				this.countEmails(this.leads)
                this.$store.commit('setIsLoading', false)
            },

            
		},
	}
</script>

<style>
	.sm {
    font-size: smaller;
    color:  #777;
    }
    .smic {
    font-size: 0.8rem;
    cursor: pointer;
    }
    .ld_itm {
    padding: 16px;
    margin: 12px 0;
    background-color: #f0f0f0;
    border-bottom: 1px solid #fff;
    }
    .prefl {
    	background-color: #bbb;
    	padding: 12px;
    	margin-bottom: 1em;

    }
    .cutp {
    	float: right;
    	cursor: pointer;
    	padding: 4px;
    	color: #933;
    }
</style>