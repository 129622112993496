<template>
	<div class="container">
    	<h1 class="title">Searches</h1> 
        <div class="control usr_filter">
            <div class="fltr_clear" @click="usr_filter = ''" v-if="usr_filter">
                × clear
            </div>
            <input type="text" class="input" v-model="usr_filter" placeholder="Filter searches. Start typing key word..">
        </div>
    	<div class="pr_srchs">

    		<div class="pr_srch" 
                v-for="psearch in psearch2"
                v-bind:key="psearch.id"
                > 

                <div  v-if="usr_filter.length <=2 || usr_filter.length > 2 
                    && psearch.query.toLowerCase().includes(usr_filter.toLowerCase())">
                
        			<span class="pr_srch_at">{{ psearch.created_at.replace("T"," ").split(".")[0] }}</span>, 
        			<span class="pr_srch_by">{{ psearch.created_by }}</span>
                    
        			<div class="pr_srch_q">
                        <!-- router-link :to="{ name: 'SearchResult', params: { id: psearch.id }}" -->
                        {{ psearch.query }}
                        <!-- /router-link -->
                    </div>
        			<span v-if="psearch.num_of_results" class="pr_srch_at">{{ psearch.num_of_results }} profiles</span>

        			
        			<!-- <button class="button" @click="xlsExport(psearch.id)">to XLSX</button> -->
                    

                </div>
    		</div>
    	</div>        
    </div>
</template>	

<script>
    import axios from 'axios'
    //import Results from '@/components/Results

    export default {
        name: 'PSearches',
        /*components: {
            Results,
        },*/
		data() {
            return {
                psearches: [],
                psearch2: [],
                usr_filter: '',
                results: false
                /* //pagination
                showNextButton: false,
                showPreviousButton: false,
                currentPage: 1,
                query: '',
                num_psearches: 0
                */
            }
        },
        created() {
            this.getPSearches()
        },
        /*
        mounted() {
    		if (localStorage.team_name) {
      			this.team_name = localStorage.team_name
    		}
    	},
        */
        methods: {

		    async getPSearches() {
		    	this.$store.commit('setIsLoading', true)

                await axios
                	.get('/lms/search/prev-searches/')
                    .then(response => {
                    	//console.log("get PSearches response.data:", response.data)
                        //console.log('usr_filter',usr_filter)
                    
                        //this.num_psearches = response.data.count
                        // this.psearches = response.data
                        this.psearch2 = response.data
                    })

                    .catch(error => {
                        if (error.response) {
                            for (const property in error.response.data) {
                                this.errors.push(`${property}: ${error.response.data[property]}`)
                            }
                        } else if (error.message) {
                            this.errors.push('Something went wrong. Please try again!')
                        }
                    })
                

				

		    	this.$store.commit('setIsLoading', false)
		    },

            async xlsExport(id) {
		    	this.$store.commit('setIsLoading', true)

                await axios
                	.get(`/lms/search/prev-searches/${id}/`)
                    .then(response => {
                    	//console.log(response.data.link)
                    	window.location.replace(process.env.VUE_APP_LMS_URL+'/lms/search/save-xls/'+response.data.link)
                    })
                    .catch(error => {
                        if (error.response) {
                            for (const property in error.response.data) {
                                this.errors.push(`${property}: ${error.response.data[property]}`)
                            }
                        } else if (error.message) {
                            this.errors.push('Something went wrong. Please try again!')
                        }
                    })
		    	this.$store.commit('setIsLoading', false)
		    }
        }
    }
</script>

<style>
	.pr_srch {
		margin:12px;
		/* border-bottom: 1px solid #aaa; */
		font-size: 12px;
		color:  #777;
	}

	.pr_srch_q {
		display:  block;
		font-size: 18px;
		font-weight: 600;
	}

    .usr_filter {
        max-width: 360px;
        font-size: 12px;
        color: #999;
    }

    .fltr_clear {
        float: right;
        color:  #c44;
        border:  0;
        background: transparent;
    }

    .fltr_clear:hover {
            
            cursor: pointer;
        } 



	

</style>