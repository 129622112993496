<template>

    <!-- SearchResultsPreview v-if="result" :result="result" @cancelresevt="cancelResult"/ -->


    <div>

        <h1 class="title">B2BSearch</h1>
        <p class="subttl">New generation AI search</p>
        <br />
        <div>
            
            <p>AI-powered search engine for sourcing leads, talent, and market research respondents based on your targeted criteria</p>

            <!--div class="lms_exmp">Examples (click to fill search form):
                <span class="lms_exmp_jt" @click="toJ('angel investor')">angel investor</span>
                <span class="lms_exmp_loc" @click="toL('Saudi Arabia')">Saudi Arabia</span>

                <span class="lms_exmp_jt" @click="toJ('aerospace engineer Lockheed Martin')">aerospace engineer Lockheed Martin</span>
                <span class="lms_exmp_loc" @click="toL('United States')">United States</span>

                <span class="lms_exmp_jt" @click="toJ('industry 4.0')">industry 4.0</span>
                <span class="lms_exmp_loc" @click="toL('Stuttgart')">Stuttgart</span>
            </div-->
        </div>
        <p>&nbsp;</p>
        <ContentButton :cbtn="{title: 'Search Strategies', url: 'help-search', padding: '15px'}" /> 
        <ContentButton :cbtn="{title: 'Learning video', url: 'video221001'}" />
        

        <p>&nbsp;</p>
        <form @submit.prevent="submitForm" autocomplete="off">
            <div class="field">
                <label><b>Job title • expertise • product • company</b> (in any language)</label>
                <div class="control">
                    <input type="text" autocomplete="off" name="job_title" class="input" v-model="job_title">
                </div>
            </div>




            <!-- location autocomplete field -->
            <div class="field">
                <label><b>Location</b></label>
                <!-- div>If you use Android, please select autosuggestion on your keyboard and then on the website</div-->
                <div class="control">
                    <div class="dropdown">
                        <div v-bind:class="{ 'is-active': acActive }" class="dropdown">
                            <div class="dropdown-trigger">

                                <!-- var 5 --
                                <input name="loc" ref="loc" v-model="loc5" maxlength="80" class="input" type="text" placeholder="Start typing location" autocomplete="off" spellcheck="false" aria-haspopup="true" aria-controls="dropdown-menu3" required>
                                !-- /var 5 -->

                                


                                <!-- var 7 full --
                                custom input event to provide compatibility with Android keyboards with typeaheads
                                https://stackoverflow.com/questions/50617865/vue-v-model-input-change-mobile-chrome-not-work
                                Test:
                                <input type='text' :value='message1' @input='evt=>message1=evt.target.value'>{{message1}}
                                --> 
                                <input name="loc" id="loc" 
                                :value="loc1"
                                @input="locevent=>loc1=locevent.target.value" 
                                maxlength="80" 
                                class="input" 
                                type="text" 
                                placeholder="Start typing location" 
                                autocomplete="off" 
                                aria-haspopup="true" 
                                aria-controls="dropdown-menu3" 
                                required>
                                <!-- /var 7 full -->


                                <!-- var 0 --
                                <input name="loc" id="loc" v-model="loc" v-on:input="getLoc" maxlength="80" class="input" type="text" placeholder="Start typing location" autocomplete="off" aria-haspopup="true" aria-controls="dropdown-menu3" required>
                                !-- /var 0 -->


                            </div>
                            <div class="dropdown-menu" id="dropdown-menu3" role="menu">
                                <div class="dropdown-content">
                                    <a v-for="location in presetLocs" 
                                       v-on:click="chooseLocation(location)" 
                                       class="dropdown-item">
                                        {{ location.name }}
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>



            <div class="notification is-danger" v-if="errors.length">
                <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
            </div>

            <div class="field">
                <div class="control">
                    <button :class="{'button':true, 'is-primary':true, 'is-loading':srcClicked}">Search</button>
                </div>
                 
            </div>
        </form>
        
        
    </div>


</template>

<script>
    import axios from 'axios'
    import {toast} from 'bulma-toast'
    //import Results from '@/components/Results'
    import SearchResultsPreview from '@/components/SearchResultsPreview'
    import ContentButton from '@/components/services/ContentButton'
    

    export default {
        name: 'SaLinkedin2209',
        components: {
            //Results,
            SearchResultsPreview,
            ContentButton,
        },
  
        data() {
            return {
                qt: '1',
                job_title: '',
                product: '',
                //loc: '',
                loc1: '',
                //myinputbox:'',
                //message1:'',
                location: {},
                presetLocs: [],
                locCode  : '',
                errors: [],
                result: false,
                acActive: false,
                //form_data: {},
                //formData: {},
                search_result: '',
                srcClicked: false,
            }
        },
        
        watch: {    
            loc1() {
                //var 5 function loc should be in "watch" section
                //let ival = this.$refs.loc.value

                //var 7
                //let loc = this.loc1

                //var 4
                //let ival = input.addEventListener('input', () => { input.value })
                //console.log("loc",this.loc1)

                if (this.loc1.length >= 2 && !this.loc1.includes('[')) {
                  axios
                    .get('/lms/search/geo-ac', {
                      params: {
                        loc: this.loc1
                      }
                    })
                    .then(response => {
                      //console.log("response.data", response.data)
                      if (response.data.length !== 0) {
                        this.acActive = true
                        this.presetLocs = response.data;
                      } else {
                        this.acActive = false
                      }
                    });
                } else {
                  this.presetLocs = []
                  this.acActive = false
                }
            },
        },
        methods: {

            async submitForm() {
                this.$store.commit('setIsLoading', true)

                if (this.job_title === '') {
                    toast({
                        message: 'Please, fill the form',
                        type: 'is-warning',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 3000,
                        position: 'center',
                    })
                } else {
                    //console.log("this.location.id", this.location.id)
                    this.srcClicked = true
                    let formData = {}

                    if (this.location.id) {
                        formData = {
                            qt: this.qt,
                            job_title: this.job_title,
                            product: this.product,
                            location: this.location
                        }
                        //console.log("autocomplete formData", formData)
                        //let x = 1
                    
                    // autocomplete + free location name
                    } else {
                        
                        formData = {
                            qt: this.qt,
                            job_title: this.job_title,
                            product: this.product,
                            location: {
                                id:"",
                                code:"",
                                name: this.loc1
                            } 
                        }
                        //console.log("non autocomplete formData", formData)
                        //let x = 2 
                    }
                
                        
                    //console.log("formData", formData)
                    await axios
                        .post('/lms/search/li/', formData)
                        .then(response => {
                            this.result = response.data
                            //console.log("result", this.result)
                            this.createProject() // skip SearchResultPreview
            
                        })
                        .catch(error => {
                            console.log(error)
                            if (error.response) {
                                this.errors.push(`Something went wrong (err ${error.response.status}). Please, try again`)
                                /*
                                for (const property in error.response.data) {  
                                    this.errors.push(`${property}: ${error.response.data[property]}`)
                                }
                                */
                            } else if (error.message) {
                                this.errors.push('Something went wrong. Please try again!')
                            }
                            this.srcClicked = false
                        })
/*                     } else { //autocomplete only
                       toast({
                        message: 'Location has to be selected from the suggestions',
                        type: 'is-warning',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 3000,
                        position: 'center',
                        })
                    }
                */
                }

 
                this.$store.commit('setIsLoading', false)
            },
            cancelResult() {
                this.result = false
                this.srcClicked = false
            },
            /*
            getLoc() {
                if (this.loc.length >= 2) {
                  axios
                    .get('/lms/search/geo-ac', {
                      params: {
                        loc: this.loc
                      }
                    })
                    .then(response => {
                      if (response.data.length !== 0) {
                        this.acActive = true
                        this.presetLocs = response.data;
                      } else {
                        this.acActive = false
                      }
                    });
                } else {
                  this.presetLocs = []
                  this.acActive = false
                }
            },
            */
            toJ (phr) {
                //if (this.job_title.includes(phr)) {
                //    console.log(this.job_title, phr)
                //} else {
                //    this.job_title += phr + ' '
                //}
                this.job_title = phr
            },
            toL (phr) {
                this.loc1 = phr
            },
            chooseLocation(location) {
                //this.locCode = location.code
                this.loc1 = location.name
                this.location = location
                this.presetLocs = [] //clear list after selection
                this.acActive = false
                //console.log(location)
            },
            clearpresetLocs() {
                this.presetLocs = []
                this.acActive = false
            },
            async createProject() {
                // for version skipping SearchResultsPreview
                this.prjClicked = true
                this.$store.commit('setIsLoading', true)
                //console.log("search id:", this.result.search_id)
                
                await axios
                    .post('/lms/project/create', {"search_id":this.result.search_id})
                    .then(response => {
                        this.project_id = response.data
                        //console.log("this.project_id", this.project_id)
                        this.$router.push(`/project/${this.project_id.project_id}`)
                    })
                    .catch(error => {
                        console.log(error)
                    })
                
                this.$store.commit('setIsLoading', false)
            }
        }
    }
</script>
<style>
    .subttl {
        font-size: 1.5rem;
        font-weight: 600;
    }
    .bbs {
        border-color: #999;
        margin-top: 18px;
    }
    .bbs:hover {
        border-color: #444;
    }
    .input {
        border-color: #999;
    }

    .lms_exmp {
        font-style: italic;
        font-size: smaller;
        margin-left: 24px;
        margin-bottom: 20px;
    }

    .lms_exmp_jt {
        display: block;
        margin-top: 5px;
        background-color: #f0f0f0;
        cursor: pointer;
        color: #00d;

    }
    .lms_exmp_loc {
        display: block;
        margin-top: 0px;
        background-color: #f0f0f0;
        cursor: pointer;
        color: #00d;
    }

</style>