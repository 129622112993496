<template>
	<button class="button" @click="saveCsv()">
		<u>&#8681;</u>&nbsp;CSV
		<span v-if="!project.mail_list_status" class="credits1">{{PRICE}}</span>
	</button>
</template>

<script>
	import axios from 'axios'

	export default {
        name: 'SaveCsv',
        data() {
            return {
                csvFN: '',
                PRICE: 20
            }
        },
        props: {
        	results: [],
        	project: {},
        },
        
        methods: {      	
        	async saveCsv() {
        		this.$store.commit('setIsLoading', true)

        		// check credits and pay credits at the backend first
        		const checkCredits = await axios
                    .post('/lms/project/save-csv/', {'project_id': this.project.id})
                //console.log('checkCredits', checkCredits.data.team_credits)
                this.$store.state.teamCredits = checkCredits.data.team_credits


		        if (!checkCredits.data.csv_downl) {
                   	this.$store.state.creditsOut = true
                   	this.$store.commit('setIsLoading', false)
                   	return {'error': 'Not enough credits'}	
		        } else {
	        		let csvFN = `b2bsearch_${this.project.id}_${this.project.title
	        			//prettify file name
	        			.replace(", loc: ","_")
	        			.replace("LI: ","")
	        			.replace(" [","[")
	        			.replaceAll(" - ","-")
	        			.replaceAll(" ","-")
	        			}.csv`
					console.log('save-csv FN:', csvFN)

					let csvContent = 'NAME1\tNAME2\tCOMPANY\tJOB\tLINKEDIN\tEMAIL\tBREIF\n'

					let persons = this.results 

					//console.log('save-csv persons:', persons)
					
					persons.forEach((row,ind) => {
						//console.log('row.email_list:', row.email_list)
						let emails = ''
						row.email_list.forEach((eml,idx) => {
							//console.log('row > eml:', eml.addr)
							emails += `${eml.addr},`
							})
						emails = emails.slice(0, -1)
						csvContent += `${row.contact_person.replace(' ','\t')}\t${row.company}\t${row.job}\t${row.linkedin}\t${emails}\t${row.snippet}\n`
						
					})

					//console.log("csvContent", csvContent)

					let link = document.createElement('a')
					link.id = "dwnld"+this.results.id
					link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csvContent));
					link.setAttribute('download', csvFN);
					document.body.appendChild(link)
					document.querySelector("#dwnld"+this.results.id).click()
					//document.querySelector(`#${this.results.id}`).remove()
				}
        		this.$store.commit('setIsLoading', false)
        	},
        	async backendCheck() {
                this.$store.commit('setIsLoading', true)

                const response = await axios
                    .post('/lms/project/save-csv/', {'project_id': this.project.id})
                    .then(response => {
                        this.$store.state.teamCredits = response.data.team_credits
                    })
                    .catch(error => {
                        console.log(error)
                    })
                this.$store.commit('setIsLoading', false)
                console.log('backendCheck', response.data.csv_downl)

            }
        },
    }
</script>