<template>
    <div class="container">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">{{ article.title }}</h1>

                <div v-html="article.body"></div>

            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'PubArticle',
        data() {
            return {
                article: {
                    url: '',
                    title: '',
                    body: '',
                    created_at: ''
                }
            }
        },
        computed() {
            this.accordionHandler()
        },
        mounted() {
            this.getPub()

        },
        methods: {
            async getPub() {
                this.$store.commit('setIsLoading', true)
                const articleURL = this.$route.params.article_url
                await axios
                    .get(`/lms/pub/${articleURL}/`)
                    .then(response => {
                        console.log(response)

                        this.article = response.data
                    })
                    .catch(error => {
                        console.log(error)
                    })

                this.$store.commit('setIsLoading', false)

            },
            

        },
        /*watch: {
          $route: {
            //immediate: true,
            handler: 'getPub'
          }
        }*/
    }
</script>

<style>
    h2,h3,h4 {
        margin-top: 20px;
    }
    
</style>